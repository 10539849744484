 
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toYYYMMDDHHMM } from "kls-commons/help/dates";  
import { currentUser } from "kls-commons/service/userService";
import { Avatar } from "kls-ui";

function NotificationPanel({ onClose, setCount }) {
    let [data, setData] = useState([] as any[]); 
    let startPolling = () => {
        console.log(">> polling");
        let request = { userId: currentUser().email, first: 0, max: 10, unseen: true  }
        searchMessage(request).then(
            resp => {
                console.log('<<' + resp);
                if (resp.result) {
                    data = resp.result.map((d: any) => d);
                    setData(data);
                    setCount(data.length)
                    // setMessage(data[0]);
                }
            }
        ); 
        setTimeout(() => startPolling(), 1000 * 300);
    }
   /* useEffect(() => {
        console.log("startPolling");
        startPolling();
    }, ['start']);
    */

    let selectNotification=(m:any) =>{
        markMessage(m).then(r=> {
            data= data.filter(x=>x!=m);
            setData(data);
            setCount(data.length);
            onClose();
        });
        
    }
    let markAllAsRead=( ) =>{
        markAllMessage(data.map(x=>x.reference).join(",")).then(r=> {
            data= [];
            setData(data);
            setCount(0)
            onClose();
        });
        
    }
    return (<>
        <div className="dropdown-menu">
            
            <h6 className="kls-notification-title">Notifications</h6>
            {(!data || data.length == 0) && <p className="kls-notification-text">Aucune nouvelle notification</p>}
            {data.length > 0 && <p className="kls-notification-text">Vous avez {data.length} notifications non lues</p>}
            <div className="kls-notification-list">
                {data.map((message, i) => {
                    return <div className="notif-element"> <Link onClick={()=>selectNotification(message)} key={message.reference + "-" + i} to={getResourceUrl(message)}>
                        <div className="media new">
                            <div className="kls-img-user online">
                                {!message.icon && <Avatar user={message.actor}></Avatar>}
                                
                                {message.icon  && <i className={message.icon} ></i> }
                                </div>
                            <div className="media-body">
                                <strong>{message.actor.name}</strong><p>{message.textIndex || message.title} </p>
                                <span>{toYYYMMDDHHMM(message.changeTime)}</span>
                            </div>
                        </div>
                    </Link>
                    </div>;
                })}

            </div>
            <div className="dropdown-footer"><a onClick={markAllAsRead}  >Marquer comme lu</a></div>
        </div>
    </>);
}

export default NotificationPanel;

function getResourceUrl(message: any): string  {
   if(message.messageType=="notification" && message.bizDomain) {
        if(message.bizDomain?.includes("TRAINING")) {
            return "/training-info/"+message.bizId;
        }
        if(message.bizDomain?.includes("ASSESSMENT-QUIZ")) {
            return "/assessment/quiz-"+message.bizId;
        }
        if(message.bizDomain?.includes("ASSESSMENT-POLLS")) {
            return "/assessment/polls-"+message.bizId;
        }
        if(message.bizDomain?.includes("KPI")) {
            return "/dashboard/"+message.bizId;
        }
        if(message.bizDomain?.includes("KPI-DATA")) {
            return "/dashboard/"+message.bizId;
        }
        if(message.bizDomain?.includes("CHALLENGE")) {
            return "/challenge/"+message.bizId;
        } 
        if(message.bizDomain?.includes("POST")) {
            return "/post/"+message.bizId;
        }
       
   } 
       return "/message/" + message.reference;
  
}
function markAllMessage(arg0: string): Promise<any>  {
    throw new Error("Function not implemented.");
}

function markMessage(m: any): Promise<any> {
    throw new Error("Function not implemented.");
}

function searchMessage(request: { userId: string; first: number; max: number; unseen: boolean; }): Promise<any> {
   return Promise.reject("not implemented");
}

