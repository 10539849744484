import { useEffect, useState } from "react"; 
import  t from "kls-i18n";
import { GS, saveGlobaleState } from "kls-commons/state/global-state";
import { commitModule, listTags } from "kls-commons/service/repo-service";
 
 

export function SaveApplicationForm(props) { 
  let [comment, setComment]= useState("");
  let [currentTag, setCurrentTag]= useState("");
  let [nextTag, setNextTag]= useState("");
  let [pushToProd, setPushToProd] = useState(false);
  let [error, setError]= useState("");
  let saveFirstService = async () => { 
     
  };

  useEffect(()=>{
    if(props.moduleName) {
      listTags(props.moduleName, '', '', true).then(r=>{
        console.log("listTags", r);
        setCurrentTag(r[0]?.tag.replace("refs/tags/", ""));
      })
    }
  }, [props.moduleName]);
  let onAction = (name) => {
    console.log("on Action");
    if (name === "close" || name === "cancel") { 
      saveGlobaleState(GS); 
      props.onClose(); 
      return;
    }
    commitModule(props.moduleName, comment, nextTag, pushToProd).then(r=>{

      console.log("commitModule", r);
      if(r.error) {
        setError(r.error)
      } else { 
        props.onClose();
      }
    })
  };

  return (
    <div className="modale-popup save-app ">
      <div className="inner-popup-mid  large ">
        <i className="fa fa-times" onClick={props.onClose}></i>
        <div className="popup-header">
          {t("Enregistrer les changements du module") +
            " " +  GS.currentModuleName}{" "}
        </div>
        <div className="popup-body">
          <div className="services-block">
            <div className="service-block-title">
               <span className="service-name">{props.moduleName}</span>{"@"}
                <span className="service-tag"  >{currentTag}</span>
 
            </div>
            <textarea className="save-comment" placeholder="Commit message" value={comment} onChange={e=>setComment(e.target.value)} ></textarea>
                      <input className="save-tag" placeholder="Next tag" value={nextTag} onChange={e=>setNextTag(e.target.value)} ></input>
            <div className="check-box-field">
            <div className={"check-box-field-square " + (pushToProd ? "selected" : "")}
                onClick={() => {   setPushToProd(!pushToProd);    }}  ></div>
            <span className="service-module">  {"Push to Prod"}</span> 
      </div>


      {error && <div className="error">{error}</div>}
          </div>
        </div>
        <div className="popup-footer">
          <button
            onClick={() => onAction("cancel")}
            className={"btn btn-tlb cancel-btn"}
          >
            <i className={"fa fa-times"}></i>
            <span>{t("Fermer")}</span>
          </button>

          <button
            onClick={() => onAction("ok")}
            className={"btn btn-tlb ok-btn"}
          >
            <i className={"fa fa-check"}></i>
            <span>{t("Valider")}</span>
          </button>
        </div>
      </div>
    </div>
  );
} 
