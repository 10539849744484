import {  useEffect, useState } from "react";
import {   useHistory } from "react-router-dom";
import  t from "kls-i18n"; 
import { DropdownList } from "kls-ui";
import { TabsBlock } from "kls-ui";
import { getWorkingDirectorTree } from "kls-commons/service/repo-service";
import { GS, saveGlobaleState } from "kls-commons/state/global-state";
import {  WorkingDirTree } from "kls-commons/types/repo-model";

const TABS = [
  { name: "forms", label: t("Formulaire") },
  { name: "searchs", label: t("Recherches") },
  { name: "tasklists", label: t("Corbeilles") },
  { name: "processes", label: t("processus") },
  { name: "menus", label: t("Menus") },
];
export function ModulesExplorer(props: any) {
let history=useHistory();  
  let [currentTab, setCurrentTab] = useState("forms");
  let [searchKey, setSearchKey] = useState("");
  

  let [forms, setForms] = useState([] as any[]);
  let [searchs, setSearchs] = useState([] as any[]);
  let [taskLists, setTaskLists] = useState([] as any[]);
  let [menus, setMenus] = useState([] as any[]);
  let [processes, setProcess] = useState([] as any[]);
  let [modules, setModules] = useState(  [] as any[][]); 
  let [models, setModels] = useState(  [] as any[][]); 
  let [currentModuleName , setCurrentModuleName] = useState(GS.currentModuleName || '');
  let [currentModelName , setCurrentModelName] = useState('');
  
  let [tree, setTree] = useState(undefined as unknown as WorkingDirTree);
  
  
  const onSelectModule = (d) => {
    console.log("onSelect ", d);
    setCurrentModuleName(d.name);
    GS.currentModuleName=d.name;
    saveGlobaleState(GS);
  }; 

  useEffect(()=>{
    getWorkingDirectorTree(currentModuleName).then(tree => {
      setTree(tree); 
    })
  }, [currentModuleName])
  useEffect(() => {
  
 

    let treex = filterModels(tree, searchKey, currentModuleName);
    forms = [];
    searchs = [];
    taskLists = [];
    processes = [];
    menus = [];
    models = [];
    parseTreeDir(tree, forms, searchs, taskLists, processes, menus, models);
   
    console.log({forms, searchs, processes, menus, models});
    setForms(forms);
    setSearchs(searchs);
    setTaskLists(taskLists);
    setMenus(menus);
    setProcess(processes);
    setModels(models);
  }, [searchKey, tree, currentModelName]);


  let startEdit= (r)=> {
    console.log("startEdit", r);
  }
  let activateModule= ()=>{ 
    history.push("/edit-module/"+currentModuleName+"/browse-models") ;
    if(props.onClose){
        props.onClose();
    }
  }

  return (
    <div className="modules-explorer">
      <div className="modules-explorer-module">
        <label>{t("Module En cours")}</label>
        <DropdownList
          data={GS.modules || []}
          value={{ name: currentModuleName }}
          onSelect={onSelectModule}
          optionRender="nameAsTitle"
        ></DropdownList>
        { <div className="service-action-btn" onClick={activateModule}>
            <i className="fa fa-folder-open"></i>
            <span>Ouvrir</span>
        </div> }
      </div>

      <div className="modules-explorer-search-bar">
        <div className="search-by-text">
          <input
            type="text"
            onChange={(e) => setSearchKey(e.target.value)}
            value={searchKey}
            placeholder="Rechercher une ressource ..."
          />
          <i className="fa fa-search"></i>
        </div>
        <DropdownList
          data={models}
          value={{ name: currentModelName || '' }}
          onSelect={(d) => setCurrentModelName(d.name)}
          optionRender="nameAsTitle"
        ></DropdownList>
      </div> 

      <TabsBlock
        tabs={TABS}
        onSelect={(name: string) => setCurrentTab(name)}
        defSelected={currentTab}
      >
        {" "}
      </TabsBlock>

      {currentTab === "forms" && (
        <div className="tab-content">
          {forms.map((r, i) => (
            <LinkForRubrique key={r.name + " " + i} rub={r} startEdit={startEdit}></LinkForRubrique>
          ))}
        </div>
      )}
      {currentTab === "searchs" && <div className="tab-content">   {searchs.map((r, i) => (
            <LinkForRubrique key={r.name + " " + i} rub={r} startEdit={startEdit}></LinkForRubrique>
          ))} </div>}
      {currentTab === "tasklists" && (
        <div className="tab-content">   {taskLists.map((r, i) => (
            <LinkForRubrique key={r.name + " " + i} rub={r} startEdit={startEdit}></LinkForRubrique>
          ))} </div>
      )}
      {currentTab === "processes" && (
        <div className="tab-content">   {processes.map((r, i) => (
            <LinkForRubrique key={r.name + " " + i} rub={r} startEdit={startEdit}></LinkForRubrique>
          ))} </div>
      )}
      {currentTab === "menus" && <div className="tab-content">   {menus.map((r, i) => (
            <LinkForRubrique key={r.name + " " + i} rub={r} startEdit={startEdit}></LinkForRubrique>
          ))} </div>}
    </div>
  );
}

function LinkForRubrique(props: any) {
  let ssr = props.rub;

  return (
    <div className="rubric-item">
      <div
        onClick={() => {
          props.startEdit(ssr);
        }}
        className="nav-link"
      >
        
          <i className={"link-icon "+ssr.icon}></i> 
          <span className="link-label"> {ssr.fileName}  </span>
      </div>
    </div>
  );
}

function mapModelNameToLabel(s: string) {
  if (!s) return s;
  return s.replace(/.*\//g, "");
}

function filterModels(
  ce: WorkingDirTree,
  searchKey: string,
  currentModelName: string
): WorkingDirTree {
  let ret =  ce;
  if (searchKey) {
    
  }
  if (currentModelName && currentModelName !== "*") { 
  }

  return ret;
}
function parseTreeDir(tree: WorkingDirTree, forms: any[], searchs: any[], taskLists: any[], processes: any[], menus: any[], models: any[]) {
  if(!tree)
    return;
  if(tree.model) {
    models.push({name: tree.info?.filePath, label: tree.info?.fileName});
  }
  if((tree.model || tree.name==="process"  || tree.name==="search" ) && tree.children) {
     for(let k of Object.keys(tree.children)) {
      let file = tree.children[k].info; 
       
      if(!file)
        continue;
      if(file.fileName.endsWith("-view.xml")) {
        forms.push({...file, icon: "fa fa-window-maximize" });
      } else if(file.fileName.endsWith("-menu.xml")) {
        menus.push({...file, icon: "fa fa-bars" });
      } else if(file.fileName.endsWith("-taskList.xml")) {
        taskLists.push({...file, icon: "fa fa-list" });
      } else if(file.fileName.endsWith(".bpmn")) {
        processes.push({...file, icon: "fa fa-cog" });
      } else if(file.fileName.endsWith(".xml") && file.filePath.includes("/search/")) {
        searchs.push({...file, icon: "fa fa-search" });
      }




     }
   } 
  if(tree.children) {
    Object.keys(tree.children!).forEach(k=> parseTreeDir(tree.children![k], forms, searchs, taskLists, processes, menus, models));
   }
}

