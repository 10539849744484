import { useEffect, useState } from "react"; 
import  t from "kls-i18n";
import { GS, saveGlobaleState } from "kls-commons/state/global-state";
import {  checkoutTag, listTags } from "kls-commons/service/repo-service";
import { toYYYMMDDHHMM } from "kls-commons/help/dates";
 
 

export function ModuleHistory(props) {  
  let [search, setSearch] = useState(""); 
  let [tags, setTags] = useState([] as any[]);
  let [error, setError]= useState("");
  let [selectedTag , setSelectedTag]= useState<any>(null);
 

  useEffect(()=>{
    if(props.moduleName) {
      listTags(props.moduleName, '', '', false).then(r=>{  
        setTags(r|| []);
      })
    }
  }, [props.moduleName]);
  let onSearch=()=>{
    listTags(props.moduleName, search, '', false).then(r=>{ 
      setTags(r|| []);
      setSelectedTag(null);
    })
  }
  let onAction = (name) => {
    console.log("on Action");
    if (name === "close" || name === "cancel") { 
      saveGlobaleState(GS); 
      props.onClose(); 
      return;
    }
    if(name === "undo" || name === "deploy") {
       checkoutTag(props.moduleName, selectedTag.tag, selectedTag.saveMessage,  name === "deploy" ? "prod": "env" ).then(r=>{
        if(r.error) {
          setError(r.error);
        } else {
          props.onClose();
        }
      });
    }
   
    
    
  };
  let onSelectTag = (tag)=> {
    setSelectedTag(tag);

  }

  return (
    <div className="modale-popup save-app ">
      <div className="inner-popup-mid  large ">
        <i className="fa fa-times" onClick={props.onClose}></i>
        <div className="popup-header">
          {t("Historiques des enregistrement")}  
        </div>
        <div className="popup-body">
          <div className="services-block"> 
          <div className="tag-search-outer">
          <input className="tag-search" placeholder="..."  value={search}  onChange={e=>setSearch(e.target.value)} ></input>
         <i className="fa fa-search"  onClick={onSearch}></i>
          </div>
            {error && <div className="error">{error}</div>}
            
            {tags.map((t,i)=><TagBlock key={t.tag+i} tag={t} 
            onSelect={t=>onSelectTag(t)} selected={t===selectedTag} ></TagBlock>)}
            {(!tags || !tags.length) && <div className="empty-message">Aucun historique de commit n'est disponible. </div>}
          </div>
        </div>
        <div className="popup-footer">
          <button
            onClick={() => onAction("cancel")}
            className={"btn btn-tlb cancel-btn"}
          >
            <i className={"fa fa-times"}></i>
            <span>{t("Fermer")}</span>
          </button>

        { selectedTag &&  <button
            onClick={() => onAction("undo")}
            className={"btn btn-tlb ok-btn"}
          >
            <i className={"fa fa-undo"}></i>
            <span>{t("Ramener cette version")}</span>
          </button> }

          { selectedTag && <button
            onClick={() => onAction("deploy")}
            className={"btn btn-tlb ok-btn"}
          >
            <i className={"fa fa-server"}></i>
            <span>{t("Déployer en Prodution")}</span>
          </button>}
        </div>
      
      </div>
    </div>
  );
}

function TagBlock(props) { 
let e=props.tag;
  
  let [message, setMessage] = useState('');
  return (
    <div className="serivce-item">
      <div  > 
        <div className="tag-info">
          <div className="tag">
          <div
          className={"check-box-field-square " + (props.selected ? "selected" : "")}
          onClick={() => {
          props.onSelect(props.selected ? null: e);
          }}
        ></div> 
        <span> {e.tag.replace("refs/tags/", "")}</span>
          </div>
         
          <span className="tag-date">{toYYYMMDDHHMM(new Date(e.time))}</span>
        </div>
        <div className="tag-message">{e.message}</div> 
       
      </div>
      {props.selected  && (
        <div className="save-detail">
          <div className="save-message"> 
            <textarea
              value={message}
              placeholder="commentaire"
              onChange={(evt) => {
                e.saveMessage = evt.target.value;
                setMessage(e.saveMessage);
              }}
            ></textarea>
          </div>
          
        </div>
      )}

      {e.error && <div className="error">{e.error}</div>}
    </div>
  );
}
