import { useState } from "react";
import  t from "kls-i18n";
import { uploadFile } from "kls-commons/service/file.service";
import { currentUser, sendChangeMyPwd, signout, storeLocalUserProfile, updateUser } from "kls-commons/service/userService";
import { UserProfile } from "kls-commons/types/people";
import { Avatar } from "kls-ui";
import { Popup } from "kls-ui";
import { TabsBlock } from "kls-ui";

function ProfileMenu(props: {postSignOut: Function, onClose: Function }) {

    let [showMyProfile, setShowMyProfile] = useState(false);
    return (
        <>
            <div tabIndex={0} onBlur={()=>props.onClose} className="dropdown-menu">
              
                <div className="kls-header-profile">
                    <div className="kls-img-user">
                        <Avatar connectedUser size="large" ></Avatar>
                    </div>
                    <h6>{currentUser().fullName}</h6>
                    <span>{currentUser().email}</span>
                </div>

                {/*   <a onClick={() => { setShowMyProfile(true); props.onClose();}} className="dropdown-item"><i className="typcn typcn-user-outline"></i>{t("Mon Profil")}</a>
                  <a className="dropdown-item"><i className="typcn typcn-edit"></i> Editer Mon Profil</a>   
                <Link to="/activity" className="dropdown-item"><i className="typcn typcn-time"></i> Journal d'Activité</Link> */}

                <a onClick={() => { storeLocalUserProfile(null); signout(); props.postSignOut(null); document.location = "/"; }} className="dropdown-item"><i className="typcn typcn-power-outline"></i> {t("Me déconnecter")}</a>
            </div>

            {showMyProfile && <MyProfile user={currentUser()} onClose={() => setShowMyProfile(false)}  ></MyProfile>}

        </>
    );
}

export default ProfileMenu;

/*  
    notification_kpi?: string;
    notification_kpi_data?: string;
    notification_post?: string;
*/
 
 


const TABS = [ 
{ name: "avatar", label: t("Avatar") },
{ name: "pwd", label: t("Mot de passe") },]



function MyProfile(props) {
    let [user, setUser] = useState(props.user as UserProfile);
    let [currentTab, setCurrentTab] = useState("notif");
    let [pwd, setPwd] = useState("");
    let [npwd, setNPwd] = useState("");
    let [npwd2, setNPwd2] = useState("");
    let [razPwd, setRazPwd] = useState(false);
    let [error, setError] = useState('');
    let [successPwdRaz, setSuccessPwdRaz]=useState(false);
    let [avatarLink, setAvatarLink] = useState(user.avatarUrl);
    let onAvatarChange = (evt: any) => {
        uploadFile(evt).then((link: any) => {
            setAvatarLink(link);
            user.avatarUrl = link;
        });
    }
    let save = (action: string) => {
        if (action == "close") {
            props.close();
        }
        updateUser(user, "myprofile").then(r => {
            console.log("myprofile update", r);
            if (r) {
                props.onClose();
            }
        });
    };
   


    let sendResetPassword =()=> {
        sendChangeMyPwd(user.email, npwd, pwd).then((r:any)=> {
            console.log("r=>", r)
            if(r.success) { 
                setSuccessPwdRaz(true);
            } else if(r.code=="PWD_FORMAT") {
                setError("Le mot de passe doit contenir au moins 8 caractères")
            }
        }, 
        err=>{
            console.log("Erreur lors du changment du mot de passe", err);
            setError("Erreur lors du changment du mot de passe");
        })
    }
    let now = new Date().getTime();
    return (<Popup onClose={props.onClose} onAction={save} medium
        actions={[{ name: 'ok', label: t('Valider'), icon: 'check' }, { name: 'cancel', label: t('Fermer'), icon: 'times' }]}>
        <div className="myprofile-panel kpi-form" >
            <TabsBlock tabs={TABS} onSelect={(name: string) => setCurrentTab(name)} defSelected="notif"></TabsBlock>
            {error && <p className="form-error">{error}</p>}

           
            {currentTab == "avatar" && <div className="avatar-container">
                <label htmlFor="avatarFile" className={'canupload'}>
                    {avatarLink && <img className="avatar-img" src={avatarLink}></img>}
                    {!avatarLink && <i className="noavatar fa fa-user-slash" ></i>}

                </label>
                <input onChange={onAvatarChange} type="file" id="avatarFile" className="h-file-input" accept="image/*"></input>
            </div>}
            {currentTab == "pwd" && <div className="form-g2c">
                <div className="col-left">
                    <div className="form-group  ">
                        <label>{t("Ancien Mot de passe")}</label>
                        <input id={"mpopx-"+now} name={"mpopx-"+now} onChange={e => setPwd(e.target.value)} value={pwd}
                            type="password" className="form-control" />
                    </div>
                    <div className="form-group  ">
                        <label>{t("Nouveau mot de passe")}</label>
                        <input id={"mpopy-"+now} name={"mpopy-"+now}  onChange={e => setNPwd(e.target.value)} value={npwd}
                            type="password" className="form-control" />
                    </div>
                    <div className="form-group  ">
                        <label>{t("Comnfirmer le nouveau mot de passe")}</label>
                        <input id={"mpopz-"+now} name={"mpopz-"+now} onChange={e => setNPwd2(e.target.value)} value={npwd2}
                            type="password" className="form-control" />
                    </div>
                </div>
                <div className="col-right pwd-bnt-pan ">
                    {npwd == npwd2 && npwd2 && npwd2.length > 4 && <div onClick={sendResetPassword} className="btn btn-tlb">
                        <i className="fa fa-key"></i> <span> &nbsp;&nbsp; Modifier mon mot de passe</span></div>}
                    {successPwdRaz && <p>
                        <div className="reconnect-prompt">{t("Votre mot de passe est modifié")}.
                        <br/>{t("Vous pouvez vous reconnecter avec le nouveau de mot de passe")}</div>
                        <div onClick={()=>{storeLocalUserProfile(null); signout(); props.postSignOut(null); document.location = "/";}} className="btn btn-tlb">
                        <i className="fas fa-sign-out-alt"></i> <span> &nbsp;&nbsp; Reconnecter</span></div>
                        </p>}
                </div>
            </div>
            }
        </div>
    </Popup>)
}
